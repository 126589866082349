// src/components/Footer.js

import React from 'react';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <p>&copy; 2023 FinanceApp. All rights reserved.</p>
            </div>
        </footer>
    );
}


export default Footer;
